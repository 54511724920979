import * as React from "react";
import { graphql } from "gatsby";
import PageLayout from "../components/page-layout/PageLayout";
import SEO from "../components/SEO";
import ArticleLayout from "../components/layout/ArticleLayout";
import LinkPreview from "../components/typography/LinkPreview";

const ResourceTemplate: React.FunctionComponent<any> = ({
  pageContext: { data },
}) => {
  return (
    <PageLayout pageName={data.slug} headerBg="gray-100">
      <SEO
        title={`${data.title} | Astrorei`}
        description={data.description}
        datePublished={new Date(data.date_published)}
        dateModified={new Date(data.date_modified)}
      />

      <ArticleLayout
        tag="astrorei"
        {...data}
        related={data.related.map((r) => ({
          ...r,
          path: `/risorse/${r.slug}`,
        }))}
      >
        <div className="space-y-4 my-12">
          {data.links.map((l) => (
            <LinkPreview key={l.url} link={l} />
          ))}
        </div>
      </ArticleLayout>
    </PageLayout>
  );
};

export default ResourceTemplate;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
