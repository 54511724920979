import React from "react";
import Title from "./Title";
import TitleTag from "./TitleTag";

const LinkPreview = ({ link, maxLen = 200 }) => {
  const siteName = link.siteName || new URL(link.url).hostname;

  return (
    <a
      href={link.url}
      target="_blank"
      className="border-2 flex rounded-md overflow-hidden flex-wrap lg:flex-nowrap"
    >
      <div className="flex-grow">
        <div className="p-4">
          <TitleTag>{siteName}</TitleTag>
          <Title>
            {link.title && link.title !== "" ? link.title : siteName}
          </Title>
          {link.description && (
            <p className="text-gray-600 leading-snug text-justify">
              {link.description.length > maxLen
                ? link.description.substring(0, maxLen) + "..."
                : link.description}
            </p>
          )}
        </div>
        <div className="flex mt-6 px-4 pb-2 items-center">
          <img className="h-4 rounded-md" src={link.favicons[0]} />
          <p className="ml-2 text-sm text-gray-400 whitespace-nowrap max-w-[250px] text-ellipsis overflow-hidden">
            {link.url}
          </p>
        </div>
      </div>
      {link.images.length > 0 && (
        <img
          className="w-full shrink-0 lg:w-1/3 object-cover h-36 md:h-56 lg:h-auto"
          src={link.images[0]}
        />
      )}
    </a>
  );
};

export default LinkPreview;
