import React, { FunctionComponent } from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import BodyText from "../typography/BodyText";
import Title from "../typography/Title";
import TimeAgo from "../typography/TimeAgo";
import { TitleHeading } from "../typography/TitleHeading";

type ArticleLayoutProps = {
  tag: string;
  title: string;
  description: string;
  date_published: string;
  date_modified: string;
  image?: string;
  keywords: string[];
  author: {
    name: string;
    image: string;
  };
  timeToRead?: number;
  related: {
    title: string;
    path: string;
    date_modified: string;
    timeToRead?: number;
  }[];
  html: string;
  children?: React.ReactNode;
};

const ArticleLayout: FunctionComponent<ArticleLayoutProps> = (props) => {
  const { t } = useTranslation();

  return (
    <section className="relative container px-5 mt-12 mb-24 mx-auto">
      <div className="lg:px-7 flex flex-col lg:flex-row lg:justify-center">
        <div className="max-w-prose mt-6">
          <TitleHeading tag={props.tag} title={props.title} titleType="h1" />
          {props.image && (
            <img
              className="rounded-lg shadow-lg object-cover object-center mt-12"
              src={props.image}
            />
          )}
          <div
            className="text-justify prose prose-headings:text-left prose-headings:font-extrabold prose-a:text-indigo-600 text-gray-600 mt-12"
            dangerouslySetInnerHTML={{ __html: props.html }}
          />
          {props.children}
        </div>

        <div className="mt-12 lg:mt-6 lg:ml-12 divide-y space-y-8 lg:max-w-xs">
          <div className="flex lg:flex-col">
            <div className="shrink-0">
              <span className="sr-only">{props.author.name}</span>
              <img
                className="h-24 w-24 rounded-full"
                src={
                  require(`../../assets/images/authors/${props.author.image}`)
                    .default
                }
              />
            </div>
            <div className="ml-8 lg:ml-0 flex flex-col justify-center">
              <p className="lg:mt-4 text-lg font-medium text-gray-900">
                {props.author.name}
              </p>
              <div className="flex space-x-1 text-sm text-gray-500">
                <TimeAgo date={props.date_modified} />
                {props.timeToRead && (
                  <>
                    <span aria-hidden="true">&middot;</span>
                    <span>{props.timeToRead} min</span>
                  </>
                )}
              </div>
              {/* <p className="text-primary mt-3 uppercase"># {props.category}</p> */}
            </div>
          </div>
          {props.related.length > 0 && (
            <div className="pt-8 space-y-6 ">
              <Title type="h4">{t("blog:article.related")}</Title>
              {props.related.map((post) => (
                <div key={post.path}>
                  <Link to={post.path}>
                    <Title small type="p" className="font-bold">
                      <span className="text-gray-700 hover:text-gray-900">
                        {post.title}
                      </span>
                    </Title>
                    <BodyText mb={0}>
                      <TimeAgo date={post.date_modified} /> &middot;{" "}
                      {post.timeToRead} min
                    </BodyText>
                  </Link>
                </div>
              ))}
            </div>
          )}
          <div className="hidden lg:block pt-8">
            <Title type="h4">{t("blog:article.cta.title")}</Title>
            <div className="mt-6">
              <a
                href="#contact-form"
                className="py-2 px-4 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 lg:text-white lg:bg-indigo-600 lg:hover:bg-indigo-700"
              >
                {t("blog:article.cta.button")} →
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ArticleLayout;
